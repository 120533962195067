export const enumFilterType = {
  TextBox: 1,
  DropDown: 2,
  DropDownMultiple: 3,
};

export const enumDocumentsSource = {
  GoogleDrive_FromURL: 1,
  GoogleDrive_FromArg: 2,
};

export const enumRequestHeader = {
  Header_Employee: "EmployeeID",
};

export const enumConfig = {
  //baseUrl: "https://test-acq-api.azurewebsites.net/", //"https://apipimsbtshe4s.azurewebsites.net/",
  baseUrl: "https://apihr-app.azurewebsites.net/",
  baseUrlDevelopment: "https://localhost:7060/", //baseUrl: 'https://localhost/',

  cachelookupvalues: false,

  exactAuthUrlLIVE:
    "https://apihr-app.azurewebsites.net/ExactAuthorize/Authenticate",
  exactAuthUrlLOCAL:
    "https://d433-62-162-133-229.ngrok-free.app/ExactAuthorize/Authenticate",

  ROLE_ADMIN: "E93FBC46-CC84-4FE3-AAC4-D2A0477B8C37",
  ROLE_ACQUISITION_ADMIN: "BD373C27-8752-4F14-B784-08CBD6E33951",
  ROLE_ACQUISITION_USER: "C5681CCA-520E-412D-88F1-5C1485B89AA7",
  ROLE_STAFF: "53F905C7-ACCD-4506-A51C-732A62170547",
  ROLE_HR_ADMIN: "68C7DC1A-0320-4E12-8668-5B8C55374E67",
  ROLE_FINANCE_ADMIN: "0A9E853B-747A-47E5-A5A7-C3FE2ABEBE69",
};

export const enumLookupTypes = {
  Regions: 1025,
  PhaseStatus: 1028,
  Donors: 1029,
  Notifications: 1032,
  Functions: 10001,
  Offices: 10002,
  Contract_Type: 10003,
  Contract_Currency: 10004,
  Contract_GrossNet: 10005,
  DiemTypes: 10006,
  VacancyCountry: 10008,
  Languages: 10009,
  EmergencyRelation: 10011,
  Onboarding: 10012,
  Offboarding: 10013,
  Competencies: 10014,
  DocumentType: 10012,
};

export const enumPagination = {
  PageSize: 10000,
  PageSizeLow: 50,
  PageSizeMax: 100000,
  PageIndex: 0,
};

export const enumPage = {
  LoadStart: 1,
  LoadSuccess: 2,
  ErrorValidation: 3,
  ErrorServer: 4,
  SaveSuccess: 5,
  ActionSuccessWithMsg: 6,
  LoadStartWithMsg: 7,
  LoadSuccessWithMsg: 8,
  LoadSuccessWithWarningMsg: 9,
  DeleteSuccess: 10,
  LoadStartWithAwait: 11
};

export const enumSharedMsgs = {
  Save: "Record Saved",
  DeleteAreYouSure:
    "Are you sure you want to delete this record, email will be sent to the related parties for this action?",
  WariningMsg: "Warning",
  Delete: "Record deleted",
  SaveClientError: "Unable to save it, please fix the errors.",
};

export const enumBreadcrumbPage = {
  Search: 1,
  Master: 2,

  Details: 3,
  Overview: 4,
  Notes: 5,

  Section_1: 6,
  Section_2: 7,
  Section_3: 8,
  Documents: 9,
  CheckList: 10,
};

export const enumRoutes = {
  Main_Dashboard: "/Home/Dashboard",
  Main_ActionsPoints: "/Home/ActionPoints",
  Main_MyEmployeeSearch: "/Home/MyEmployeeSearch",
  Main_MyProfile: "/Home/MyProfile",
  Main_MyOrgChart: "/Home/OrgChart",
  Main_MyEmployeeToDOs: "/Home/MyEmployeeToDOs",
  
  Timesheet_FinTime_MyTimeEntries: "/Timesheet/MyTimeEntries/:Year",
  Timesheet_FinTime_New: "/Timesheet/fintime_new/:StaffID/:Month/:Year",
  Timesheet_FinTime_Edit: "/Timesheet/fintime_edit/:SummaryID",
  Timesheet_FinTime_Preview: "/Timesheet/fintime_preview/:SummaryID/:showMsg",
  Timesheet_FinTime_Search: "/Timesheet/fintime_search/",
  Timesheet_FinTime_EditAdmin: "/Timesheet/fintime_edit_admin/:SummaryID",
  Timesheet_FinTime_PreviewAdmin: "/Timesheet/fintime_preview_admin/:SummaryID/:showMsg",
  RepTimesheet_EmployeeAllocation: "/RepTimesheet/FinRepEmployeeAllocation",

  Acquisition_Dashboard: "/Acquisition/Dashboard",
  Acquisition_New: "/Acquisition/new",
  Acquisition_Edit: "/Acquisition/edit/:AcquisitionID",
  Acquisition_Notes_Edit: "/Acquisition/Notes/edit/:AcquisitionID",
  Acquisition_Overview: "/Acquisition/overview/:AcquisitionID",
  Acquisition_Search: "/Acquisition/search",
  Acquisition_SearchWithArguments: "/Acquisition/search/:ArgumentIDs",

  SystemUser_Overview: "/SystemUser/overview/:UserId",
  SystemUser_New: "/SystemUser/new",
  SystemUser_Search: "/SystemUser/search",
  SystemUser_Edit: "/SystemUser/edit/:UserId",

  User_Overview: "/User/overview",
  User_ChangePassword: "/User/changepassword",

  SystemLog_Search: "/SystemLog/search",

  Notification_Root: "/Notification/",
  Notification_Search: "/Notification/search",
  Notification_New: "/Notification/new",
  Notification_Bulk: "/Notification/bulk",

  Timesheet_Payroll_Search: "/Timesheet/payroll_search",
  Timesheet_FinProject_Preview: "/Timesheet/finproject_preview/:ProjectID/:showMsg",
  Timesheet_FinProject_New: "/Timesheet/new",
  Timesheet_FinProject_Search: "/Timesheet/finproject_search",
  Timesheet_FinProject_Edit: "/Timesheet/finproject_edit/:ProjectID/",
  Timesheet_FinProject_Overview: "/Timesheet/finproject_overview/:ProjectID/",
  Timesheet_FinProject_OverviewPlanning: "/Timesheet/finproject_overview_planning/:ProjectID/",
  Timesheet_FinProject_OverviewSpent: "/Timesheet/finproject_overview_spent/:ProjectID/",
  Timesheet_FinProject_Edit_WithMsg:
    "/Timesheet/finproject_edit/:ProjectID/:showMsg",
  Timesheet_FinBudget_Edit: "/Timesheet/finbudget_edit/:ProjectID",
  Timesheet_FinAllocation_Edit: "/Timesheet/finallocation_edit/:ProjectID",
  Timesheet_FinProject_Submit: "/Timesheet/finproject_submit/:ProjectID",
  Timesheet_FinProject_Verify: "/Timesheet/finproject_verify/:ProjectID/:StageID",

  Timesheet_FinAllocation_ByEmployee: "/Timesheet/finallocation_employee/:EmployeeID/:Year",

  Timesheet_FinAllocation_Search: "/Timesheet/finallocation_search",
  Timesheet_Payroll_SearchByArgs: "/Timesheet/payroll_search_byargs/:OfficeID/:Year/:Month", //OfficeID/Year/month 
  Timesheet_Payroll_Verify: "/Timesheet/payroll_verify/:OfficeID/:Year/:Month", //OfficeID/Year/month 
  Timesheet_Office_Search: "/Timesheet/office_search",
  

  Security_Login: "/Login",
  Security_ManualLogin: "/ManualLogin",
  Security_GoogleLogin: "/GoogleLogin",
  Security_MicrosoftLogin: "/MicrosoftLogin",
  Security_Logout: "/Logout",
  Security_LoginExternal: "/LoginExternal/:ArgumentIDs",

  Employee_Register: "/Employee/Register",

  Employee_Dashboard: "/Employee/Dashboard",
  //Employee_ActionPoints: "/Employee/ActionPoints",
  Employee_New: "/Employee/new",
  Employee_Edit: "/Employee/edit/:EmployeeID",

  //TypeOfSource: URL ili pak ARGUMENTS
  Employee_Documents:
    "/Employee/Documents/:EmployeeID/:InputFolderID/:TypeOfSource",

  Employee_Overview: "/Employee/overview/:EmployeeID",
  Employee_Search: "/Employee/search",
  Employee_TransferHoursSearch: "/Employee/TransferHours",
  Employee_Contract_Get: "/Employee/Contracts/get/:EmployeeID",
  Employee_Contract_Edit: "/Employee/Contracts/edit/:ContractID",
  Employee_CheckList: "/Employee/CheckList/:EmployeeID/:LookupTypeID",

  Employee_CheckList_Onboarding:
    "/Employee/CheckList/Onboarding/:EmployeeID/:LookupTypeID",
  Employee_CheckList_Offboarding:
    "/Employee/CheckList/Offboarding/:EmployeeID/:LookupTypeID",
  Employee_CheckList_Competencies:
    "/Employee/CheckList/Competencies/:EmployeeID/:LookupTypeID",

  LeaveRequest_Dashboard: "/LeaveRequest/search",
  LeaveRequest_Search: "/LeaveRequest/search",
  LeaveRequest_Report_Summary: "/LeaveRequest/reportsummary",
  LeaveRequest_MyApprovals: "/LeaveRequest/myapprovals",
  LeaveRequest_MyRequests: "/LeaveRequest/myrequests",
  LeaveRequest_New: "/LeaveRequest/new",
  LeaveRequest_PublicHoliday_New: "/LeaveRequest/publicnew",
  LeaveRequest_Approval: "/LeaveRequest/approval/:RequestID",
  LeaveRequest_Calendar: "/LeaveRequest/calendar",
  LeaveRequest_Preview: "/LeaveRequest/preview/:RequestID",

  Declaration_Dashboard: "/Declaration/search",
  Declaration_MyDeclarations: "/Declaration/mydeclarations",
  Declaration_MyApprovals: "/Declaration/myapprovals",
  Declaration_New: "/Declaration/new",
  Declaration_BudgetHolderApproval:
    "/Declaration/budgetholderapproval/:DeclarationID",
  Declaration_FinanceApproval: "/Declaration/financeapproval/:DeclarationID",
  Declaration_Cost_Edit: "/Declaration/Cost/:DeclarationID",
  Declaration_Submit: "/Declaration/Submit/:DeclarationID",
  Declaration_Preview: "/Declaration/Preview/:DeclarationID",
  Declaration_Edit: "/Declaration/edit/:DeclarationID",
  Declaration_Diem_Edit: "/Declaration/Diem/:DeclarationID",

  //Admin Links for Declaration
  Declaration_Search: "/Declaration/search",
  Declaration_PreviewAdmin: "/Declaration/PreviewAdmin/:DeclarationID",
  Declration_ProjectBudgetHolderChange:
    "/Declaration/ProjectBudgetHolderChange",

  Exactonline_Search: "/Exactonline/search",
  Exactonline_Overview: "/Exactonline/overview/:Code/:Year",
  Exactonline_Migration: "/Exactonline/migration",
  Exactonline_TokenReply: "/Exactonline/tokenreply",

  Staff_LeaveRequest_New: "/Staff/LeaveRequest/new",

  Vacancy_Search: "/Vacancy/search",
  Vacancy_New: "/Vacancy/new",
  Vacancy_Applicant: "/Vacancy/applicant",
  Vacancy_Edit: "/Vacancy/edit/:VacancyID",
  VacancyApplicant_Search: "/Vacancy/applicant/search",
  VacancyApplicant_Preview: "/Vacancy/applicant/edit/:AplicantID",

  Documents_Search: "/Documents/Search",
  //Staff_LeaveRequest_MySearch: "/Staff/LeaveRequest/search",
  //Staff_ActionPoints_Search: "/Staff/ActionPoints/search",
  //Staff_Dashboard: "/Staff/Dashboard",
};

export const enumData = {
  LEAVEREQUEST_STATUS_FOR_APPROVAL: [
    { Value: 0, Text: "-All-" },
    { Value: 1, Text: "Approved" },
    { Value: 2, Text: "Pending for approval" },
  ],

  LEAVEREQUEST_STATUS_FOR_SUBMITTING: [
    { Value: 1, Text: "Approved" },
    { Value: 2, Text: "Submitted" },
  ],
  DECLARATION_STATUS_FOR_SUBMITTING: [
    { Value: 3, Text: "Budget Holder Approved" },
    { Value: 1, Text: "Finance  Approved" },
    { Value: 2, Text: "Submitted" },
  ],

  LEAVEREQUEST_TYPES: [
    { Value: 1528, Text: "Leave Request" },
    { Value: 1529, Text: "Public Holiday" },
    { Value: 1729, Text: "Rest and Recuperation" },
    { Value: 1730, Text: "Sick Leave" },
  ],

  YEARS: [
    { Value: 2023, Text: "2023" },
    { Value: 2024, Text: "2024" },
  ],

  FINTIME_STATUSES: [{ Value: 0, Text: "All" }, { Value: 1, Text: "Open" }, { Value: 2, Text: "Submitted" }],


  FINPROJECT_STATUSES: [
    { Value: "0", Text: "All" },
    { Value: "1", Text: "Active & Draft" },
    { Value: "2", Text: "Active with revisions & Draft" },
    { Value: "3", Text: "Draft" },
    { Value: "4", Text: "Submitted" },
    { Value: "5", Text: "Not Active" } 
  ],

  MONTHS: [
    { Value: 1, Text: "January" },
    { Value: 2, Text: "February" },
    { Value: 3, Text: "March" },
    { Value: 4, Text: "April" },
    { Value: 5, Text: "May" },
    { Value: 6, Text: "June" },
    { Value: 7, Text: "July" },
    { Value: 8, Text: "August" },
    { Value: 9, Text: "September" },
    { Value: 10, Text: "Octomber" },
    { Value: 11, Text: "November" },
    { Value: 12, Text: "December" },
  ],
};

export const enumLeaveRequest = {
  Leave: 1528,
  PublicHoliday: 1529,
  RRleave: 1729,
  SickLeave: 1730,
};

export const enumLeaveRequestStatus = {
  Approved: 1,
  Submitted: 2,
};

export const enumLocalStorageArgs = {
  DeclarationSearch: "ARGS_DeclarationSearch",
  EmployeeSearch: "ARGS_EmployeeSearch",
  TransferHoursSearch: "ARGS_TransferHoursSearch",
  PayrollSearch: "ARGS_PayrollSearch",
  FinProjectSearch: "ARGS_FinProjectSearch",
  ExactOnlineSearch: "ARGS_ExactOnlineSearch",
  LeaveRequestSearch: "ARGS_LeaveRequestSearch",
  UrlKeys: "ARGS_UrlKeys",
};

export const enumSQLS = {
  KEY_LIST_EmployeeSearch: 1531,


  KEY_GET_Employee: 1532,  //// i za mULTIPLE raboti  
  KEY_LIST_CountrySearch: 1533,
  KEY_GET_Country: 1534,
  KEY_LIST_GeneralSearch: 1536,

  KEY_LIST_FinBudgetSearch: 1812,
  KEY_GET_FinBudget: 1813,

  KEY_LIST_FinProjectSearch: 1817,
  KEY_GET_FinProject: 1818,


  KEY_LIST_FinProjectSearchActive: 1814, //bez revizii
  KEY_GET_MULTI_FinProject: 1815,
  KEY_GET_MULTI_Employee: 1816,  //// i za mULTIPLE raboti  
};

export const enumContractType = {
  ContractType_TransferPreviousYear: 1462,
  ContractType_ClosingContract: 1778,
};
