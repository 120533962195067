// @ts-check
import * as React from "react";
import Typography from "@mui/material/Typography";
import MyText from "../../controls/MyText";
import { useEffect, useState } from "react";
import MyAlert from "../../controls/MyAlert";
import {
  enumData,
  enumLocalStorageArgs,
  enumPage,
  enumPagination,
  enumRoutes,
} from "../../helpers/common.constants";
import Button from "@mui/material/Button";
import {
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableRow,
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import MyDropDown from "../../controls/MyDropDown";
import Link from "@mui/material/Link";
import { MessageHelper } from "../../helpers/MessageHelper";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import FinProjectActions from "../../actions/FinProjectActions";
import CurrencyHelper from "../../helpers/CurrencyHelper";
import moment from "moment";
import AddIcon from "@mui/icons-material/Add";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DownloadActions from "../../actions/DownloadActions";
import MyCheckList from "../../controls/MyCheckList";

const RecordDefault = {
  OfficeID: 1445,
  CountryIDs: "",
  StatusID: 1,
  Name: "",
  PhaseIDs: "",
  ActiveFL: "1",
  TypeOfContractID: 0,
  ExpirationContractDays: 0,

  //Pagination
  PageIndex: 0,
  PageSize: enumPagination.PageSizeLow,
  TotalRecords: 0,
  TotalPages: 0,
  Year: 2024,
  Month: 1,
  Lock: false,
  OfficeName: "",
  VerifierName: "", //Verifier
};

const styles = {
  TDSmall2: { mt: 0, mb: 0, pt: 0, pb: 0 },
};

export default function FinProjectSearch() {
  const [Record, setRecord] = useState(RecordDefault);
  const [MessageData, setMessageData] = useState({});
  const [Records, setRecords] = useState([]);
  const [IsInitDone, setIsInitDone] = useState(false);
  const [IsPaginationChange, setPaginationChange] = useState(false);
  const [IsDoSearch, setIsDoSearch] = useState(false);
  const [LookupData, setLookupData] = useState([]);

  //za Navigacija
  const navigate = useNavigate();

  //Za Refreshiranje na strana mu treba;
  const location = useLocation();

  //za poraki pri loadiranje na strana
  const msgHelper = new MessageHelper();

  //****************************************** */
  //HOOKS SECTION HERE
  //****************************************** */

  //1 --> Ke se povika ovoj HOOK samo dokolku se SMENI url-ot poradi toa na new dodavame Ticks() za da forcirame refresh na strana na sekoj click
  useEffect(() => {
    initData();
  }, [location.pathname + "/" + location.search]);

  //2 --> Od koga e sredena InitData() povikaj Search  (https://stackoverflow.com/questions/54069253/the-usestate-set-method-is-not-reflecting-a-change-immediately)
  useEffect(() => {
    if (IsInitDone) {
      searchData(); 
    }
  }, [IsInitDone]);

  // //3 --> Pagination Change
  useEffect(() => {
    if (IsPaginationChange) {
      searchData();
    }
  }, [IsPaginationChange]);

  //4 --> Search Click
  useEffect(() => {
    if (IsDoSearch) {
      searchData();
    }
  }, [IsDoSearch]);

  //****************************************** */
  //METHOD  SECTION HERE
  //****************************************** */
  const initData = () => {
      //Proveri dali slucajno ima vo Local Storage definirani Search Arguments
      setRecord(RecordDefault);
      let RecordFromStorage = new LocalStorageHelper().GetJSON(enumLocalStorageArgs.FinProjectSearch);
      if (RecordFromStorage) {
        let newRecord = RecordDefault;
        newRecord.timestamp = Date.now(); //to fore refresh
        newRecord.PageIndex = 0;  
        newRecord.StatusID = RecordFromStorage.StatusID;
        newRecord.Year = RecordFromStorage.Year; 
        newRecord.Name = RecordFromStorage.Name;  
        setRecord(newRecord);
      }

          
    async function fetchData() {
      //Clean all statesLock      setIsInitDone(false);

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStart);
      setMessageData(result);

      let responses = await new FinProjectActions().InitAsync(); // await LookupActions.InitAsync();
      if (!responses.error) {
        let lookupData = responses[0].data;

        setLookupData(lookupData);
        setIsInitDone(true);

        //Load Finished
        result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  const searchData = () => {
    searchDataAsync();
  };

  async function searchDataAsync() {
    //Load Start
    setRecords([]);
    let result = msgHelper.setPhase(enumPage.LoadStart);
    setMessageData(result);

    let responses = await new FinProjectActions().SearchAsync(Record);
    if (!responses.error) {
      const records = responses[0].data;

      //For Pagination purposes
      const pageIndex = responses[0].headers["content-pageindex"];
      const totalRecords = responses[0].headers["content-totalrecords"];
      const totalPages = responses[0].headers["content-totalpages"];
      setRecord((a) => ({
        ...a,
        ["PageIndex"]: pageIndex,
        ["TotalRecords"]: totalRecords,
        ["TotalPages"]: totalPages,
      }));
      setPaginationChange(false); //za da se pali na sleden page
      setIsDoSearch(false); //za da se pali na Search Click

      setRecords(records);


      //setiraj JSON vo Local Storage za da citas posle od tamu na sleden comeback
      new LocalStorageHelper().SetJSON(
        enumLocalStorageArgs.FinProjectSearch,
        Record
      );

      //Load Finished
      result = msgHelper.setPhase(enumPage.LoadSuccess);
      setMessageData(result);
    } else {
      //Load with Error
      setIsDoSearch(false); //za da se pali na Search Click
      result = msgHelper.setPhase(enumPage.ErrorServer, responses);
      setMessageData(result);
    }
  }

  const handleSearch = (e) => {
    setRecord((a) => ({ ...a, ["PageIndex"]: 0 }));
    setIsDoSearch(true);
  };

  const handleChangeSearch = (e) => {
    const { name, value, text } = e.target;
    console.log(name + ":" + value);
    //setRecord((prevState) => ({ ...prevState, [name]: value, ["PageIndex"]: 0 }));

    setRecord((a) => ({ ...a, [name]: value }));
  };

  const handleAddRow = () => {
    navigate(enumRoutes.Timesheet_FinProject_New);
  };

  const handleExcelExport = () => {
    async function fetchData() {

      //Load Start
      let result = msgHelper.setPhase(enumPage.LoadStartWithAwait);
      setMessageData(result);



      let responses = await new FinProjectActions().ExportAsync(Record);

      if (!responses.error) {
        //Load Finished
        let result = msgHelper.setPhase(enumPage.LoadSuccess);
        setMessageData(result);

        //DOWNLOADING EXCEL FILE
        const excelFileUrl = responses[0].data;
        let payload = "?inputFile=" + excelFileUrl;
        let downloadResponse = await new DownloadActions().DownloadFileAsync(
          payload
        );

        // create file link in browser's memory
        const href = URL.createObjectURL(downloadResponse.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", excelFileUrl); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
        return;
      } else {
        //Load with Error
        result = msgHelper.setPhase(enumPage.ErrorServer, responses);
        setMessageData(result);
      }
    }
    fetchData();
  };

  // const handleExcelExport  = () => {
  //   alert("EXPORT NA SEARCH podatocite");
  // };

  return (
    <Typography sx={{ my: 5, mx: 2 }} color="text.secondary" align="left">
      <MyAlert {...MessageData} />

      <Card sx={{ minWidth: 275, borderRadius: 2, mb: 2 }}>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={2}>
              <MyDropDown
                Id="Year"
                Record={Record}
                PlaceHolder="Select Year"
                HandleChange={handleChangeSearch}
                Options={enumData.YEARS}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <MyText
                Id="Name"
                PlaceHolder="Name"
                Record={Record}
                HandleChange={handleChangeSearch}
                ValidationMessages={Record.ValidationMessages}
                Required={true}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <MyDropDown
                Id="StatusID"
                Record={Record}
                PlaceHolder="Statuses"
                HandleChange={handleChangeSearch}
                Options={enumData.FINPROJECT_STATUSES}
              />
            </Grid>


            <Grid item xs={12} sm={12} md={2}>
              <Button
                sx={{ mt: 1 }}
                variant="contained"
                color="success"
                size="small"
                disabled={MessageData.Loading}
                onClick={() => handleSearch()}
              >
                Search
              </Button>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2}>
              <Typography variant="subtitle1" align="left">
                Total Records found: {Record.TotalRecords}
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle1" align="left">
                {Record.VerifierName !== "" &&
                  "Verifier for Office:" +
                  Record.OfficeName +
                  " is " +
                  Record.VerifierName}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <RenderTable
        Records={Records}
        onAddRow={handleAddRow}
        onExportExcel={handleExcelExport}
        MessageData={MessageData}
      />
    </Typography>
  );
}

const RenderTable = ({ Records, onAddRow, onExportExcel, MessageData }) => {
  //Record = Pagination
  const navigate = useNavigate();

  return React.useMemo(() => {
    return (
      <TableContainer
        sx={{ display: { xs: "block", sm: "block", md: "block" } }}
      >
        <Table
          sx={{ minWidth: 650, border: "1" }}
          size="small"
          aria-label="a dense table"
        >
          <TableHead>
            <TableRow>
              <TableCell colSpan={20} align="center" className="header-export">
                <Button
                  sx={{ mr: 2, mb: 1 }}
                  variant="contained"
                  startIcon={<AddIcon />}
                  size="small"
                  color="warning"
                  //disabled={MessageData.Loading || IsLocked}
                  onClick={() => onAddRow()}
                >
                  Add Project
                </Button>

                <Button
                  sx={{ mr: 2, mb: 1 }}
                  variant="contained"
                  startIcon={<FileDownloadIcon />}
                  size="small"
                  color="warning"
                  disabled={MessageData.Loading}
                  onClick={() => onExportExcel()}
                >
                  Export Excel
                </Button>
              </TableCell>
            </TableRow>

            <TableRow>
              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Project
                </Typography>
              </TableCell>
              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Year
                </Typography>
              </TableCell>
              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Start/End Date
                </Typography>
              </TableCell>
              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Holder
                </Typography>
              </TableCell>

              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Project Amount 
                </Typography>
              </TableCell>

              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Budget Amount 
                </Typography>
              </TableCell>

              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Alloc. Amount 
                </Typography>
              </TableCell>


              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Spent Amount 
                </Typography>
              </TableCell>

              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Balance Amount  
                </Typography>
              </TableCell>

              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Status
                </Typography>
              </TableCell>

              <TableCell  className="tablecell header">
                <Typography variant="h6" align="center">
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Records.map((row, index) => (
              <TableRow
                key={row.eyEmployeeID}
                className="table-row"

              //sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell className="tablecell">
                  {index + 1}.
                  <Link
                    href={enumRoutes.Timesheet_FinProject_OverviewPlanning.replace(
                      ":ProjectID",
                      row.fpProjectID
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                  //target="_blank"
                  >
                    {row.fpName} - Ver:{row.fpVersionNo}
                  </Link>
                </TableCell>
                <TableCell className="tablecell">{row.fpYear}</TableCell>
                <TableCell className="tablecell" align="center">
                  {moment(row.fpStartDate).format("DD/MM/YYYY")} -{" "}
                  {moment(row.fpEndDate).format("DD/MM/YYYY")}
                </TableCell>
                <TableCell className="tablecell">
                  {row.fpOwnerName}
                </TableCell>
                <TableCell className="tablecell" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.fpAmount, 0)}
                </TableCell>
                <TableCell className="tablecell" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.fpCalcBudgetAmount, 0)}
                </TableCell>
                <TableCell className="tablecell" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.fpCalcAllocateAmount, 2)}
                </TableCell>
                <TableCell className="tablecell" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.fpCalcSpentAmount, 2)}
                </TableCell>
                <TableCell className="tablecell" align="right">
                  {new CurrencyHelper().ToMoneyFormatDigits(row.fpCalcBalanceAmount , 2)}
                </TableCell>                                                                

                <TableCell className="tablecell">
                  {row.PhaseInfo}
                </TableCell>
                <TableCell align="center" className="tablecell">
                  <Link
                    sx={{ mr: 1.5 }}
                    href={enumRoutes.Timesheet_FinBudget_Edit.replace(
                      ":ProjectID",
                      row.fpProjectID
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                  //target="_blank"
                  >
                    Budget Lines
                  </Link>
                  |
                  <Link
                    sx={{ ml: 1.5 }}
                    href={enumRoutes.Timesheet_FinAllocation_Edit.replace(
                      ":ProjectID",
                      row.fpProjectID
                    )}
                    underline="none"
                    rel="noopener noreferrer"
                  //target="_blank"
                  >
                    Staff Allocations
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }, [Records, MessageData]);
};
